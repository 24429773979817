'use client';

import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  darkenColor,
  getColorLuminance,
  hexToRgba,
  lightenColor,
  rgbToHex,
} from '@bloom/ui/utils/color';

import Loader from '@bloom/library/components/Loader';

import css from './PrimaryButton.module.css';

export enum PrimaryButtonSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum PrimaryButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DASHED = 'dashed',
  CUSTOM = 'custom',
}

interface IPropsBase extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  contentClassName?: string;
  'data-testid': string;
  loading?: boolean;
  size?: PrimaryButtonSize;
}
type TPropsAnchor = IPropsBase & React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a' };
// Button is the default tagName
type TPropsButton = IPropsBase & React.ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };

type IPropsStandardColor = (TPropsAnchor | TPropsButton) & {
  variant?: Omit<PrimaryButtonVariant, PrimaryButtonVariant.CUSTOM>;
};

type IPropsCustomColor = (TPropsAnchor | TPropsButton) & {
  color: string;
  variant: PrimaryButtonVariant.CUSTOM;
};

type TProps = IPropsStandardColor | IPropsCustomColor;

const PrimaryButton = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<TProps>>(
  (props, forwardedRef) => {
    const {
      as = 'button',
      children,
      className,
      color,
      contentClassName,
      disabled,
      loading = false,
      size = PrimaryButtonSize.MD,
      style,
      variant = PrimaryButtonVariant.PRIMARY,
      ...restProps
    } = props;

    const [baseColor, darkColor, lightColor, textColor] = useMemo(() => {
      if (props.variant === 'custom' && props.color) {
        const [r = 255, g = 255, b = 255] =
          (props.color.startsWith('#') ? hexToRgba(props.color) : props.color)
            .match(/\d+/g)
            ?.map((n) => +n) || [];

        const baseColor = [r, g, b].join(', ');

        const darkColor = darkenColor({ b, g, r }, 0.3).join(', ');

        const lightColor = lightenColor({ b, g, r }, 0.5).join(', ');

        const textColor = getColorLuminance(rgbToHex({ b, g, r })) > 0.7 ? '#000' : '#fff';
        return [baseColor, darkColor, lightColor, textColor];
      }
      return [];
    }, [props.color, props.variant]);

    const loaderVariant = useMemo(() => {
      if (variant === PrimaryButtonVariant.PRIMARY) {
        return 'light';
      }

      if (variant === 'custom' && props.color) {
        return getColorLuminance(props.color) > 0.7 ? 'dark' : 'light';
      }

      return 'dark';
    }, [props.color, variant]);

    return React.createElement(as, {
      'aria-disabled': disabled || loading,
      children: (
        <>
          <div
            className={twMerge(
              twMerge(
                'flex items-center justify-center gap-2 whitespace-nowrap',
                loading ? 'invisible' : ''
              ),
              contentClassName
            )}
          >
            {children}
          </div>
          {loading ? <Loader data-testid="loader" type={loaderVariant} /> : null}
        </>
      ),
      className: twMerge(
        'relative inline-flex items-center justify-center rounded-sm text-sm font-medium min-w-32 cursor-pointer',
        disabled ? 'cursor-not-allowed' : '',
        loading ? 'cursor-wait' : '',

        twMerge(
          size === PrimaryButtonSize.LG ? 'h-13 px-4' : '',
          size === PrimaryButtonSize.MD ? 'h-11 px-4' : '',
          size === PrimaryButtonSize.SM ? 'h-8 px-3' : ''
        ),

        variant === PrimaryButtonVariant.PRIMARY
          ? twMerge(
              css.primary,
              'text-white',
              disabled || loading ? '!shadow-none' : '',
              !disabled || loading ? 'bg-red' : '',
              disabled && !loading ? 'bg-red-light' : '',
              !disabled && !loading ? 'focus:bg-red-dark hover:bg-red-dark' : ''
            )
          : '',

        variant === 'secondary'
          ? twMerge(
              'border border-black text-cta-black dark:border-white dark:text-cta-white',
              disabled || loading
                ? 'border-black-15 dark:border-white-15 text-cta-black-15 dark:text-cta-white-15 border'
                : '',
              !disabled && !loading
                ? 'hover:bg-black hover:text-cta-white hover:dark:bg-white hover:dark:text-cta-black'
                : ''
            )
          : '',

        variant === 'dashed'
          ? twMerge(
              'dashed-border border border-transparent',
              !disabled && !loading
                ? 'hover:bg-black hover:text-white hover:after:content-none'
                : '',
              disabled || loading ? 'text-grey' : ''
            )
          : '',

        variant === 'custom'
          ? twMerge(
              css.custom,
              disabled ? css.customDisabled : '',
              getColorLuminance(props.color) > 0.7 ? 'text-black' : '',
              getColorLuminance(props.color) <= 0.7 ? 'text-white' : ''
            )
          : '',
        className
      ),
      disabled: disabled || loading,
      ref: forwardedRef,
      style: {
        '--bloom-button-color': baseColor,
        '--bloom-button-color-dark': darkColor,
        '--bloom-button-color-light': lightColor,
        color: textColor,
        ...style,
      },
      ...(as === 'button' && { type: 'button' }),
      ...restProps,
    });
  }
);

export { PrimaryButton };
