__turbopack_context__.v({
  "background": "Loader-module__qUVtCa__background",
  "dash": "Loader-module__qUVtCa__dash",
  "lg": "Loader-module__qUVtCa__lg",
  "light": "Loader-module__qUVtCa__light",
  "md": "Loader-module__qUVtCa__md",
  "path": "Loader-module__qUVtCa__path",
  "rotate": "Loader-module__qUVtCa__rotate",
  "sm": "Loader-module__qUVtCa__sm",
  "spinner": "Loader-module__qUVtCa__spinner",
  "xs": "Loader-module__qUVtCa__xs",
});
