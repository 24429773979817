import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { LoginAttemptRequest } from '@bloom/codegen/models/LoginAttemptRequest';
import { LoginAttemptResponse } from '@bloom/codegen/models/LoginAttemptResponse';
import { PasswordReminderRequest } from '@bloom/codegen/models/PasswordReminderRequest';
import { PasswordReminderResponse } from '@bloom/codegen/models/PasswordReminderResponse';
import { UserRequest } from '@bloom/codegen/models/UserRequest';
import { UserResponse } from '@bloom/codegen/models/UserResponse';
import { UtmRequest } from '@bloom/codegen/models/UtmRequest';
import { UtmResponse } from '@bloom/codegen/models/UtmResponse';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { PersistStateKeyEnum } from '@bloom/library/constants/persist-state-keys';
import { deleteCookie, setCookie } from '@bloom/library/utils/browser';

import { SharedQueryKeyEnum } from './interface';

export function useAuthentication() {
  const queryClient = useQueryClient();
  const { handleDelete, post } = useFetch();

  const { post: postLogin, status: loginStatus } = useFetch();
  const { post: postUser, status: userStatus } = useFetch();
  const { post: postPasswordReminder, status: passwordReminderStatus } = useFetch();

  const { showErrorMessageFromResponse } = useInfoMessage();

  const login = useCallback(
    async (
      request: LoginAttemptRequest,
      options: { headers?: HeadersInit; withRedirect?: boolean } = {}
    ) => {
      const { email, password } = request;
      const { headers, withRedirect = true } = options;

      const response = await postLogin<
        { 'login-attempt': LoginAttemptResponse },
        LoginAttemptRequest
      >(
        '/api/login-attempts',
        { email: email.trim(), password },
        { headers: { Authorization: undefined, ...headers } }
      );

      if (response.status === AsyncStatusEnum.SUCCESS) {
        const data = response.data['login-attempt'];
        const { webToken } = data;

        // Don't rely on session cookies because they doesn't get cleared
        // in FF and Chrome. Instead set it to expire in 12 hours.
        const date = new Date();
        date.setHours(date.getHours() + 12);
        const expires = date.toUTCString();

        setCookie('bloom_token', encodeURIComponent(webToken), expires);

        let redirectUrl = window.location.origin;

        const searchParams = new URLSearchParams(window.location.search);

        if (withRedirect) {
          const redirectTo = searchParams.get('rd');

          if (redirectTo) {
            const pathname = decodeURIComponent(redirectTo) || '/portal';
            searchParams.delete('rd');

            let queryString = searchParams.toString();
            if (queryString) {
              queryString = `?${queryString}`;
            }

            redirectUrl = `${redirectUrl}${pathname}${queryString}`;
          } else {
            let queryString = searchParams.toString();
            if (queryString) {
              queryString = `?${queryString}`;
            }
            redirectUrl = `${redirectUrl}/portal${queryString}`;
          }

          window.location.href = redirectUrl;
        }
      }

      if (response.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(response);
      }

      return response;
    },
    [postLogin, showErrorMessageFromResponse]
  );

  const logout = useCallback(async () => {
    deleteCookie('bloom_token');
    await handleDelete(`${window.location.origin}/api/set-freelancer-id`);

    deleteCookie('__bloom_freelancer_id');

    localStorage.removeItem('bloom-get-stream-auth-token');
    localStorage.removeItem('bloom-get-stream-auth-user');
    localStorage.removeItem(PersistStateKeyEnum.CLIENT_ACCOUNT_ID);

    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  }, [handleDelete]);

  const restorePassword = useCallback(
    async (request: PasswordReminderRequest) => {
      const response = await postPasswordReminder<
        { 'password-reminders': PasswordReminderResponse },
        PasswordReminderRequest
      >('/api/password-reminders', request, { headers: { Authorization: undefined } });

      if (response.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(response);
      }

      return response;
    },
    [postPasswordReminder, showErrorMessageFromResponse]
  );

  const signup = useCallback(
    async (request: UserRequest) => {
      const response = await postUser<{ user: UserResponse }, UserRequest>('/api/users', request, {
        headers: { Authorization: undefined },
      });

      if (response.status === AsyncStatusEnum.SUCCESS) {
        queryClient.setQueryData([SharedQueryKeyEnum.USER_ME], () => response.data.user);

        const utmParams = {};
        if (typeof window !== 'undefined') {
          const matches = window.document.cookie.match(/utm_[^=]+=[^;]+(?:;|$)/g);
          if (matches) {
            matches.forEach((match) => {
              const [key, value] = match.split('=');
              utmParams[key.replace('utm_', '')] = value.replace(/;$/, '');
            });

            // save utm params
            post<UtmResponse, UtmRequest>('/api/utms', {
              modelId: response.data.user.id,
              modelType: 'USER',
              ...utmParams,
            });
          }
        }
      }

      if (response.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(response);
      }

      return response;
    },
    [post, postUser, queryClient, showErrorMessageFromResponse]
  );

  return useMemo(
    () => ({
      isLoginIn: loginStatus === AsyncStatusEnum.PENDING,
      isRestoringPassword: passwordReminderStatus === AsyncStatusEnum.PENDING,
      isSigningUp: userStatus === AsyncStatusEnum.PENDING,
      login,
      logout,
      restorePassword,
      signup,
    }),
    [loginStatus, passwordReminderStatus, userStatus, login, logout, restorePassword, signup]
  );
}
