import React from 'react';

import { twMerge } from 'tailwind-merge';

interface IProps {
  className?: string;
  ['data-testid']?: string;
  type?: 'dark' | 'light';
}

const Loader: React.FC<IProps> = (props) => {
  const { className = '', type = 'dark' } = props;
  return (
    <span
      className={twMerge(
        'absolute top-1/2 left-1/2 flex h-2 w-8 -translate-x-1/2 -translate-y-1/2 items-center justify-between',
        className
      )}
      data-testid={props['data-testid']}
    >
      <i
        className={twMerge(
          'animate-blink block h-[7px] w-[7px] rounded-full opacity-30',
          type === 'dark' ? 'bg-black dark:bg-white' : '',
          type === 'light' ? 'bg-white dark:bg-black' : ''
        )}
      />
      <i
        className={twMerge(
          'animate-blink animation-delay-400 block h-[7px] w-[7px] rounded-full opacity-30 delay-400',
          type === 'dark' ? 'bg-black dark:bg-white' : '',
          type === 'light' ? 'bg-white dark:bg-black' : ''
        )}
      />
      <i
        className={twMerge(
          'animate-blink animation-delay-800 block h-[7px] w-[7px] rounded-full opacity-30 delay-800',
          type === 'dark' ? 'bg-black dark:bg-white' : '',
          type === 'light' ? 'bg-white dark:bg-black' : ''
        )}
      />
    </span>
  );
};

export default Loader;
