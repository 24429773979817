export enum PersistStateKeyEnum {
  ACTIVE_ACCOUNT_ID = 'bloom_active_account_id',
  /**
   * CLIENT_ACCOUNT_ID used in client portal and represents the freelancer the client work with
   * Using the id we filter projects we fetch from the server
   */
  CLIENT_ACCOUNT_ID = 'bloom-cl-portal',
  /** DEFAULT_PROJECTS_ROUTE used in freelancer portal
   * on projects route we have 2 tab: all projects, and workflows
   * store the last tab user was on, so when the click projects on the sidebar
   * we open the last tab they were on
   */
  DEFAULT_PROJECTS_ROUTE = 'default-projects-route',
  /**
   * REFERRAL_WIDGET_EXPANDED_STATE used to persist the referral widget expanded or collapsed state
   * in the dashboard
   */
  REFERRAL_WIDGET_EXPANDED_STATE = 'bloom_referral_widget_expanded',
  /**
   * REFERRAL_WIDGET_VISIBLE used to persist the referral widget visible state
   * in the dashboard
   */
  REFERRAL_WIDGET_VISIBLE = 'bloom_referral_widget_visible',
  /**
   * SIDEBAR_EXPANDED used in freelancer portal to persist the state of the sidebar
   */
  SIDEBAR_EXPANDED = 'bloom_sidebar_expanded',
  /**
   * WIDGET_AUTO_OPEN_CHAT overrides the widget popup behavior
   * In the portal settings a user can set "autoPopup" in x seconds
   * To auto open the chat when the user lands on page where the widget is
   * But we do not want to auto open the chat on certain pages, like
   * contracts, invoices, etc
   * Also we ALWAYS want to auto open the chat on the client portal regardless of the settings
   */
  WIDGET_AUTO_OPEN_CHAT = 'bloom_auto_open_chat',
  /**
   * WIDGET_BUTTON_TEXT_EXCEPTION used to remove the chat button text
   * on certain pages, like client-portal, contracts, invoices, etc
   */
  WIDGET_BUTTON_TEXT_EXCEPTION = 'bloom_widget_button_text_exception',
}
