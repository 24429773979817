const colorLogShade = (color: Record<'r' | 'g' | 'b', number>, blandFactor: number) => {
  // https://github.com/PimpTrizkit/PJs/blob/master/pSBC.js
  const { r: red, g: green, b: blue } = color;

  // eslint-disable-next-line sort-keys
  const targetColor = blandFactor < 0 ? { r: 0, g: 0, b: 0 } : { r: 255, g: 255, b: 255 };

  blandFactor = Math.abs(blandFactor);

  const multiplier = 1 - blandFactor;

  const shadedRed = Math.round((multiplier * red ** 2 + blandFactor * targetColor.r ** 2) ** 0.5);
  const shadedGreen = Math.round(
    (multiplier * green ** 2 + blandFactor * targetColor.g ** 2) ** 0.5
  );
  const shadedBlue = Math.round((multiplier * blue ** 2 + blandFactor * targetColor.b ** 2) ** 0.5);

  return [shadedRed, shadedGreen, shadedBlue];
};

export function lightenColor(props: { b: number; g: number; r: number }, amount = 0.1) {
  return colorLogShade(props, amount);
}

export function darkenColor(props: { b: number; g: number; r: number }, amount = 0.1) {
  return colorLogShade(props, amount * -1);
}

export function getHexColorCodes(color) {
  let normalizedColor = color.replace(/#/, '');

  if (!/^([0-9a-fA-F]{3}){1,2}$/.test(normalizedColor)) {
    normalizedColor = 'FFFFFF';
  }

  if (normalizedColor.length === 3) {
    normalizedColor = normalizedColor
      .split('')
      .map((char) => char + char)
      .join('');
  }

  const colorCodes = normalizedColor.match(/.{2}/g);

  const r = Math.max(0, Math.min(255, parseInt(colorCodes[0], 16)));
  const g = Math.max(0, Math.min(255, parseInt(colorCodes[1], 16)));
  const b = Math.max(0, Math.min(255, parseInt(colorCodes[2], 16)));

  return [r, g, b];
}

// The idea stolen from here https://stackoverflow.com/a/1855903/4725218
export function getColorLuminance(color = '') {
  const [r, g, b] = getHexColorCodes(color);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // > 0.5 light color
  // < 0.5 dark color
  return luminance;
}

export function rgbToHex(props: { a?: number; b: number; g: number; r: number }) {
  const { a, r, g, b } = props;
  if ([r, g, b].some((value) => typeof value === 'undefined')) {
    return '';
  }

  if ([r, g, b].some((value) => value < 0 || value > 255)) {
    return '';
  }

  // Convert each component to a hexadecimal string
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  let hexColor = `#${rHex}${gHex}${bHex}`;

  if (typeof a !== 'undefined') {
    const aHex = Math.round(a * 255)
      .toString(16)
      .padStart(2, '0');
    hexColor += aHex;
  }

  return hexColor;
}

export function hexToRgba(hex = '', opacity = 100) {
  const [r, g, b] = getHexColorCodes(hex);
  const a = Math.max(0, Math.min(opacity, 100)) / 100;

  return `rgba(${r},${g},${b},${a})`;
}
