'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  autoUpdate,
  arrow,
  flip,
  FloatingPortal,
  offset,
  OffsetOptions,
  Placement,
  safePolygon,
  shift,
  Strategy,
  useClick,
  useDismiss,
  useFloating,
  useTransitionStyles,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { twMerge } from 'tailwind-merge';

interface IPropsBase {
  arrowClassName?: string;
  asChild?: boolean;
  className?: string;
  content: React.ReactNode;
  contentClassName?: string;
  customBoundary?: { getBoundingClientRect(): DOMRect; getClientRects(): DOMRectList };
  defaultOpen?: boolean;
  enabled?: boolean;
  hoverDelay?: {
    close: number;
    open: number;
  };
  offsetProps?: OffsetOptions;
  placement?: Placement;
  root?: HTMLElement | undefined;
  strategy?: Strategy;
  triggerEvent?: 'hover' | 'click';
}

interface IPropsUncontrolled extends IPropsBase {
  onOpenChange?: never;
  open?: never;
}

interface IPropsControlled extends IPropsBase {
  onOpenChange: (newOpen: boolean) => void;
  open: boolean;
}

export type TooltipProps = IPropsUncontrolled | IPropsControlled;

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = (props) => {
  const {
    arrowClassName,
    asChild = true,
    children,
    className,
    content,
    contentClassName,
    customBoundary,
    defaultOpen = false,
    enabled = true,
    hoverDelay = 75,
    offsetProps,
    placement = 'top-start',
    root,
    strategy,
    triggerEvent = 'hover',
  } = props;

  const [isOpen, setState] = useState(defaultOpen);

  const openControlled = 'open' in props ? props.open : isOpen;

  const arrowRef = useRef(null);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if ('onOpenChange' in props && props.onOpenChange) {
        props.onOpenChange(newOpen);
      } else {
        setState(newOpen);
      }
    },
    [props.onOpenChange]
  );

  const {
    context,
    placement: floatingPlacement,
    refs,
    strategy: floatingStrategy,
    x,
    y,
  } = useFloating({
    middleware: [
      offset({
        alignmentAxis: 16,
        mainAxis: 10,
        ...(typeof offsetProps === 'object' ? offsetProps : {}),
      }),
      // NOTE: it is important not change the order of middleware.
      // The order of middleware execution is important for the correct positioning of the tooltip.
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
    onOpenChange: handleOpenChange,
    open: openControlled,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
  });

  const { styles } = useTransitionStyles(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useHover(context, {
      delay: hoverDelay,
      enabled: triggerEvent === 'hover' && enabled,
      handleClose: safePolygon({ requireIntent: false }),
    }),
    useClick(context, { enabled: triggerEvent === 'click' && enabled }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (openControlled && customBoundary) {
      refs.setReference(customBoundary);
    }
  }, [customBoundary, openControlled, refs]);

  useEffect(() => {
    if (customBoundary) {
      function handleMouseDown(event: MouseEvent) {
        if (refs.floating.current?.contains(event.target as Element | null)) {
          return;
        }

        handleOpenChange(false);
      }

      window.addEventListener('mousedown', handleMouseDown);

      return () => {
        window.removeEventListener('mousedown', handleMouseDown);
      };
    }

    return undefined;
  }, [customBoundary, handleOpenChange, refs]);

  return (
    <>
      {asChild && React.isValidElement(children) ? (
        React.cloneElement(children, {
          ...getReferenceProps({
            className: twMerge(className, children.props.className),
            ref: refs.setReference,
          }),
        })
      ) : (
        <div
          ref={refs.setReference}
          {...getReferenceProps({ className: twMerge(className, 'inline-flex') })}
        >
          {children}
        </div>
      )}
      {openControlled && content ? (
        <FloatingPortal root={root}>
          <div
            {...getFloatingProps({
              className: twMerge(
                'py-2 px-3 bg-black text-white border-regular text-xs font-regular z-1000 whitespace-normal',
                floatingPlacement === 'bottom-end' || floatingPlacement === 'left-start'
                  ? 'rounded-b-lg rounded-tl-lg'
                  : '',
                floatingPlacement === 'bottom-start' || floatingPlacement === 'right-start'
                  ? 'rounded-b-lg rounded-tr-lg'
                  : '',
                ['top', 'right', 'bottom', 'left'].includes(floatingPlacement) ? 'rounded-lg' : '',
                floatingPlacement === 'top-end' || floatingPlacement === 'left-end'
                  ? 'rounded-t-lg rounded-bl-lg'
                  : '',
                floatingPlacement === 'top-start' || floatingPlacement === 'right-end'
                  ? 'rounded-t-lg rounded-br-lg'
                  : '',
                contentClassName
              ),
              ref: refs.setFloating,
              style: {
                left: x ?? 0,
                maxWidth: 256,
                position: floatingStrategy,
                top: y ?? 0,
                ...styles,
              },
            })}
          >
            {content}
            <div
              className={twMerge(
                'absolute h-0 w-0 border-solid',
                floatingPlacement === 'right-end'
                  ? 'border-b-active right-full bottom-0 border-t-0 border-r-0 border-b-8 border-l-8 border-t-transparent border-r-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'bottom'
                  ? 'border-b-active bottom-full left-1/2 -translate-x-1/2 border-r-[6px] border-b-[6px] border-l-[6px] border-t-[0] border-t-transparent border-r-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'bottom-end'
                  ? 'border-b-active right-0 bottom-full border-t-0 border-r-0 border-b-8 border-l-8 border-t-transparent border-r-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'left-end'
                  ? 'border-l-active bottom-0 left-full border-t-8 border-r-0 border-b-0 border-l-8 border-t-transparent border-r-transparent border-b-transparent'
                  : '',
                floatingPlacement === 'bottom-start'
                  ? 'border-l-active bottom-full left-0 border-t-8 border-r-0 border-b-0 border-l-8 border-t-transparent border-r-transparent border-b-transparent'
                  : '',
                floatingPlacement === 'left'
                  ? 'border-l-active top-1/2 left-full -translate-y-1/2 border-t-[6px] border-r-0 border-b-[6px] border-l-[6px] border-t-transparent border-r-transparent border-b-transparent'
                  : '',
                floatingPlacement === 'top-end'
                  ? 'border-r-active top-full right-0 border-t-0 border-r-8 border-b-8 border-l-0 border-t-transparent border-b-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'right-start'
                  ? 'border-r-active top-0 right-full border-t-0 border-r-8 border-b-8 border-l-0 border-t-transparent border-b-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'right'
                  ? 'border-r-active top-1/2 right-full -translate-y-1/2 border-t-[6px] border-r-[6px] border-b-[6px] border-l-0 border-t-transparent border-b-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'top-start'
                  ? 'border-t-active top-full left-0 border-t-8 border-r-8 border-b-0 border-l-0 border-r-transparent border-b-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'top'
                  ? 'border-t-active top-full left-1/2 -translate-x-1/2 border-t-[6px] border-r-[6px] border-b-0 border-l-[6px] border-r-transparent border-b-transparent border-l-transparent'
                  : '',
                floatingPlacement === 'left-start'
                  ? 'border-t-active top-0 left-full border-t-8 border-r-8 border-b-0 border-l-0 border-r-transparent border-b-transparent border-l-transparent'
                  : '',
                arrowClassName
              )}
              ref={arrowRef}
            />
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
};
