import React from 'react';

import { twMerge } from 'tailwind-merge';

import style from './Loader.module.css';

export enum LoaderSizeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum LoaderVariantEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

interface ILoaderProps {
  className?: string;
  size?: LoaderSizeEnum;
  spinnerClassName?: string;
  variant?: 'light' | 'dark';
}

const LoaderV2: React.FC<ILoaderProps> = (props) => {
  const {
    className,
    size = LoaderSizeEnum.SM,
    spinnerClassName,
    variant = LoaderVariantEnum.LIGHT,
  } = props;

  return (
    <div
      className={twMerge(
        'absolute top-1/2 left-1/2 inline-flex -translate-x-1/2 -translate-y-1/2',
        style[variant],
        style[size],
        className
      )}
      data-testid="loader"
    >
      <svg className={twMerge(style.spinner, spinnerClassName)} viewBox="0 0 50 50">
        <g fill="none" strokeWidth="4">
          <circle className={style.background} cx="50%" cy="50%" r="20" stroke="#252525" />
          <circle className={style.path} cx="50%" cy="50%" r="20" stroke="#FFF" />
        </g>
      </svg>
    </div>
  );
};

export { LoaderV2 };
